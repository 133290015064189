<template>
  <fm-modal
    :value="openDialog"
    width="800px"
    theme="mh-withe"
    @cancel="handleClose">
    <div>
      <fm-title title-text="查询配置" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu"></fm-title>
      <div class="form-item">
        <div class="form-item-label" style="width: 15rem;min-width: 15rem;max-width:15rem;">是否为查询配置接口</div>
        <div class="form-item-content">
          <fm-checkbox v-model="apiConf.isConfQuery"></fm-checkbox>
        </div>
      </div>
      <div class="form-item" v-if="!apiConf.isConfQuery">
        <div class="form-item-label">目标表配置</div>
        <div class="form-item-content">
          <fm-select 
            style="width: 10rem;margin-left: 1rem;"
            v-model="apiConf.confTableId">
            <fm-option
              v-for="itemO in confTableData"
              :key="itemO.key"
              :label="itemO.label" :value="itemO.key">
            </fm-option>
          </fm-select>
        </div>
      </div>
      <div class="form-item" v-if="apiConf.isConfQuery">
        <div class="form-item-label">查询配置</div>
        <div class="form-item-content">
          <fm-select 
            style="width: 10rem;margin-left: 1rem;"
            v-model="apiConf.confQueryId">
            <fm-option
              v-for="itemO in confQueryData"
              :key="itemO.key"
              :label="itemO.label" :value="itemO.key">
            </fm-option>
          </fm-select>
        </div>
      </div>
    </div>
  </fm-modal>
</template>

<script>
import {
  confApiRequest
} from '@/api'

let baseData = {
  isConfQuery: false,
  confTableId: '',
  confQueryId: ''
}

export default {
  name: 'conf_api_modal',
  props: {
    openDialog: {
      type: Boolean
    },
    confData: {
      type: Object
    },
    confQueryData: {
      type: Array
    },
    confTableData: {
      type: Array
    }
  },
  data () {
    return {
      titleMenus: [{
        key: 'save',
        label: '保存'
      }],
      apiConf: baseData
    }
  },
  created () {
    let data = this.confData.dataConf ? JSON.parse(this.confData.dataConf) : {}
    this.apiConf = Object.assign({}, baseData, {
      isConfQuery: data.isConfQuery,
      confTableId: data.confTable ? data.confTable.id : null,
      confQueryId: data.confQuery ? data.confQuery.id : null
    })
  },
  methods: {
    getConfColumnByConfQuery (confQueryTable) {
      let confColumn = []
      let findConfTable = this.confTableData.find(v => v.data.tableName === confQueryTable.table)
      if (findConfTable) {
        if (confQueryTable.result.isAll) {
          confColumn = findConfTable.data.confColumn.filter(v => !confQueryTable.result.excludeColumns.includes(v.columnName))
        } else {
          confColumn = confQueryTable.result.fields.map((v) => {
            return Object.assign({}, findConfTable.data.confColumn.find(v2 => v2.columnName === v.column), {columnName: v.alias || v.columnName})
          })
        }
      }
      return confColumn
    },
    async clickTitleMenu () {
      let dataConf = {
        isConfQuery: this.apiConf.isConfQuery
      }
      if (this.apiConf.isConfQuery) {
        dataConf.confQuery = this.apiConf.confQueryId ? this.confQueryData.find(v => v.key === this.apiConf.confQueryId).data || null : null
        if (dataConf.confQuery && dataConf.confQuery.conf) {
          let conf = JSON.parse(dataConf.confQuery.conf)
          dataConf.confQuery.confColumn = this.getConfColumnByConfQuery(conf)
          conf.joins.forEach((item) => {
            dataConf.confQuery.confColumn = dataConf.confQuery.confColumn.concat(this.getConfColumnByConfQuery(item))
          })
        }
      } else {
        dataConf.confTable = this.apiConf.confTableId ? this.confTableData.find(v => v.key === this.apiConf.confTableId).data || null : null
      }
      confApiRequest.update(this.confData.id, Object.assign({}, this.confData, {
        dataConf: JSON.stringify(dataConf)
      }))
    },
    handleClose () {
      this.$emit('handleClose')
    }
  }
}
</script>

<style lang="less" scoped>
.title-c {
  display: flex;
  align-items: center;
}
.form-item-table {
  height: 10rem;
  width: 100%;
}
.form-item {
  display: flex;
  margin: 0.5rem 1rem;
  display: flex;
  align-items: center;
  .form-item-label {
    min-width: 5rem;
    max-width: 5rem;
    width: 5rem;
  }
  .form-item-content {
    flex: 1;
  }
}
</style>
