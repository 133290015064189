<template>
  <div class="sign-page">
    <div class="sign-page-div">
      <div class="sign-page-content content-block">
        <div class="content-top">
          <fm-title title-text="API配置" :title-menus="titleMenus" @clickTitleMenu="clickTitleMenu">
          </fm-title>
        </div>
        <div class="content-body">
          <fm-table
            :column-list="columnList"
            :data-list="dataList"
            @tableAction="tableAction"
            :table-actions="tableActions"
            :show-search="true">
          </fm-table>
        </div>
      </div>
    </div>
    <data-update
      title-text="API配置"
      :open-dialog.sync="openDialogEdit"
      :open-dialog-del.sync="openDialogDel"
      :request="request"
      :his-data="chooseData"
      @updateOver="loadData"
      @handleClose="openDialogEdit = false;openDialogDel = false"
      :form-parms="formParms">
    </data-update>
    <conf-modal
      v-if="openDialogConf"
      :confQueryData="confQueryData"
      :confTableData="confTableData"
      :open-dialog.sync="openDialogConf"
      @handleClose="openDialogConf = false"
      :conf-data="chooseData">
    </conf-modal>
  </div>
</template>

<script>
import ConfModal from './conf_api/conf_modal'
import {
  confApiRequest as request,
  confTableRequest,
  confQueryRequest,
  confColumnRequest
} from '@/api'

export default {
  components: {
    ConfModal
  },
  data () {
    return {
      confQueryData: [],
      confTableData: [],
      confColumnData: [],
      request: request,
      chooseData: null,
      dataList: [],
      openDialogEdit: false,
      openDialogDel: false,
      openDialogConf: false,
      formParms: [{
        type: 'input',
        label: '地址',
        key: 'path'
      },
      {
        type: 'input',
        label: '请求类型',
        key: 'method'
      },
      {
        type: 'input',
        label: '名称',
        key: 'parm'
      },
      {
        type: 'input',
        label: '类型',
        key: 'type'
      },
      {
        type: 'input',
        label: '配置',
        key: 'dataConf'
      }]
    }
  },
  computed: {
    titleMenus () {
      return [{
        key: 'add',
        label: '新增'
      }]
    },
    tableActions () {
      return [{
        key: 'conf',
        label: '配置'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    columnList () {
      return this.formParms.map((v) => {
        return {
          field: v.key,
          title: v.label
        }
      })
    }
  },
  created () {
    this.loadData()
    this.loadCQ()
    this.loadCT()
  },
  methods: {
    async loadData () {
      this.dataList = await request.get()
    },
    async loadCQ () {
      let data = await confQueryRequest.get()
      this.confQueryData = data.map((v) => {
        return {
          key: v.id,
          label: v.name,
          data: v
        }
      })
    },
    async loadCT () {
      let data = await confTableRequest.get()
      let cData = await confColumnRequest.get()
      data = data.map((v) => {
        v.confColumn = cData.filter(vc => vc.confTableId === v.id)
        return {
          key: v.id,
          label: v.tableName,
          data: v
        }
      })
      this.confTableData = data
    },
    formHandleClose () {
      this.openDialog = false
    },
    async clickTitleMenu (parm) {
      this.chooseData = null
      if (parm === 'add') {
        this.openDialogEdit = true
      }
    },
    async tableAction (parm) {
      this.chooseData = parm.data
      if (parm.action === 'edit') {
        this.openDialogEdit = true
      } else if (parm.action === 'del') {
        this.openDialogDel = true
      } else if (parm.action === 'conf') {
        this.openDialogConf = true
      }
    }
  }
}
</script>

<style scoped lang="less">
@import '../../styles/values.less';
.content-bottom {
  height: 4.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sign-page-div {
  margin: 1rem 1rem 0 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sign-page-div-no-padding {
  margin: 0;
}
.sign-page {
  width: 100%;
  height: 100%;
  display: flex;
}
.sign-page-top-1 {
  margin-bottom: 1rem;
}
.sign-page-top-2 {
  margin-bottom: 1.25rem;
}
.sign-page-content {
  flex: 1;
}
.content-body {
  flex: 1;
}
</style>
